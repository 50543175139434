<template>
  <div class="main-content pa-4 pa-sm-6 pa-md-8">
    <v-row>
      <v-col cols="12" md="8">
        <div class="d-flex flex-column bs-gap-3">
          <Carousel />
          <AvailableGames />
          <FIBAGames />
          <ScheduledGames />
          <Winners />
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="d-flex flex-column bs-gap-3">
          <v-card
            class="livestream bs-shadow-none bs-position-relative bs-overflow-hidden"
          >
            <img
              src="@/assets/livestream-thumbnail.webp"
              alt="..."
              style="display: block; width: 100%; height: auto"
            />
            <a
              href="https://www.facebook.com/100082248056340/videos/689448509497266"
              target="_blank"
              rel="noopener noreferrer"
              class="livestream-overlay d-flex justify-center align-center"
            >
              <i class="fas fa-play fa-2x"></i>
            </a>
          </v-card>
          <Announcements />
          <v-card class="app bs-shadow-none">
            <div class="pa-4 pa-sm-6">
              <h3 class="font-weight-bold mb-2">GameX App</h3>
              <div class="grey--text mb-4">
                <!-- Download the app for the smoother and happier betting experience. -->
                {{ $t("home.download.subtitle") }}
              </div>
              <div class="mb-4 mb-sm-6">
                {{
                  android_count == "0" ? "..." : `${android_count} Downloads`
                }}
              </div>
              <v-btn
                class="light primary--text bs-position-relative ps-4"
                href="apk/gamex.apk"
                depressed
                style="width: 100%; text-transform: initial"
                @click="doUpdateDownloadAndroidCount"
              >
                <div
                  class="bs-position-absolute bs-top-0 bs-start-0 bs-bottom-0 d-flex align-center"
                >
                  <i class="fas fa-download"></i>
                </div>
                <!-- Download App -->
                {{ $t("home.download.button") }}
              </v-btn>
            </div>
          </v-card>
          <Testimonials />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel";
import AvailableGames from "@/components/app/home_page/available_games/AvailableGames.vue";
import FIBAGames from "@/components/app/home_page/fiba_games/FIBAGames.vue";
import ScheduledGames from "@/components/app/home_page/scheduled_games/ScheduledGames.vue";
import Winners from "@/components/app/home_page/winners/Winners.vue";
import Announcements from "@/components/Announcements.vue";
import Testimonials from "@/components/Testimonials.vue";

export default {
  name: "AppHome",
  components: {
    Carousel,
    AvailableGames,
    FIBAGames,
    ScheduledGames,
    Winners,
    Announcements,
    Testimonials,
  },
};
</script>
