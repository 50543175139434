<template>
  <div class="main-content pa-4 pa-sm-6 pa-md-8">
    <div v-if="!recordsLoading">
      <div v-if="records && records.length != 0">
        <v-card class="leagues elevation-10" style="border: none !important">
          <div class="pa-4 pa-sm-6">
            <div class="d-flex" style="float: right">
              <v-dialog
                ref="filter"
                v-model="filter"
                :return-value.sync="date"
                width="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    :color="filter ? 'primary' : undefined"
                    icon
                    v-bind="attrs"
                    v-on="on"
                    dark
                  >
                    <v-icon>mdi-calendar-clock</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text @click="filter = false"> Cancel </v-btn>
                  <v-btn depressed class="primary" @click="filterDate"> Ok </v-btn>
                </v-date-picker>
              </v-dialog>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    small
                    :to="{ name: 'Tutorial' }"
                    dark
                  >
                    <v-icon>mdi-help-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>How to play</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    small
                    :to="{ name: 'MyBets' }"
                    dark
                  >
                    <v-icon>mdi-clipboard-text-outline</v-icon>
                  </v-btn>
                </template>
                <span>Transactions</span>
              </v-tooltip>
              <!-- <Tutorial /> -->
            </div>
            <div class="d-flex flex-column">
              <div class="d-flex align-center bs-gap-2">
                <h1 class="font-weight-bold white--text">
                  Basketball Ending
                </h1>
              </div>
            </div>

            <div class="grey--text mt-2">Select a league</div>
            <div v-if="!recordsLoading">
              <div class="mt-6">
                <v-slide-group>
                  <v-slide-item>
                    <v-btn
                      @click="league_id = '0'"
                      depressed
                      class="light me-3"
                      :class="league_id == '0' ? 'v-btn--active' : undefined"
                      style="width: 64px !important; height: 64px !important"
                    >
                      ALL
                    </v-btn>
                  </v-slide-item>
                  <v-slide-item v-for="(item, key) in mergedLeagues" :key="key">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="league_id = item[0].league_id"
                          depressed
                          class="light me-3"
                          style="width: 64px !important; height: 64px !important"
                          v-bind="attrs"
                          v-on="on"
                          :class="
                            league_id == item[0].league_id ? 'v-btn--active' : undefined
                          "
                        >
                          <v-avatar size="64" class="rounded-lg pa-4">
                            <v-img
                              :src="item[0].league_logo"
                              :alt="item[0].league"
                              contain
                            >
                            </v-img>
                          </v-avatar>
                        </v-btn>
                      </template>
                      <span>{{ item[0].league }}</span>
                    </v-tooltip>
                  </v-slide-item>
                </v-slide-group>
              </div>
            </div>
            <div class="d-flex mt-6" style="gap: 12px" v-else>
              <div class="rounded-lg overflow-hidden">
                <v-skeleton-loader
                  type="image"
                  width="64"
                  height="64"
                ></v-skeleton-loader>
              </div>
              <div class="rounded-lg overflow-hidden">
                <v-skeleton-loader
                  type="image"
                  width="64"
                  height="64"
                ></v-skeleton-loader>
              </div>
              <div class="rounded-lg overflow-hidden">
                <v-skeleton-loader
                  type="image"
                  width="64"
                  height="64"
                ></v-skeleton-loader>
              </div>
            </div>

            <div class="mt-4">
              <v-text-field
                v-model="search"
                placeholder="Start typing name of a team"
                single-line
                hide-details="auto"
                clearable
                dense
                outlined
                dark
                color="primary"
                class="search-input"
              ></v-text-field>
            </div>

            <div v-if="!recordsLoading">
              <div class="league-schedule pt-4">
                <v-slide-group>
                  <v-slide-item>
                    <v-btn
                      depressed
                      class="light me-3"
                      style="width: 64px !important; height: 64px !important"
                      @click="schedule = null"
                      :class="schedule == null ? 'v-btn--active' : undefined"
                    >
                      ALL
                    </v-btn>
                  </v-slide-item>
                  <v-slide-item v-for="(item, key) in mergedSchedules" :key="key">
                    <v-btn
                      depressed
                      class="light me-3"
                      style="width: 64px !important; height: 64px !important"
                      @click="schedule = item[0].formatted_schedule"
                      :class="
                        schedule == item[0].formatted_schedule
                          ? 'v-btn--active'
                          : undefined
                      "
                    >
                      <div class="d-flex flex-column align-center">
                        <span class="font-weight-bold">
                          {{ item[0].schedule.substring(8, 11) }}
                        </span>
                        <span class="text-uppercase" style="font-size: 12px">
                          {{ getDayOfDate(item[0].schedule) }}
                        </span>
                      </div>
                    </v-btn>
                  </v-slide-item>
                </v-slide-group>
              </div>
            </div>
            <div class="d-flex mt-4" style="gap: 12px" v-else>
              <div class="rounded-lg overflow-hidden">
                <v-skeleton-loader
                  type="image"
                  width="64"
                  height="64"
                ></v-skeleton-loader>
              </div>
              <div class="rounded-lg overflow-hidden">
                <v-skeleton-loader
                  type="image"
                  width="64"
                  height="64"
                ></v-skeleton-loader>
              </div>
              <div class="rounded-lg overflow-hidden">
                <v-skeleton-loader
                  type="image"
                  width="64"
                  height="64"
                ></v-skeleton-loader>
              </div>
            </div>
          </div>
        </v-card>

        <div class="mt-6">
          <div
            v-if="recordsLoading"
            class="pa-12 d-flex align-center justify-center text-center bs-gap-3 grey--text"
          >
            <v-progress-circular
              :size="14"
              :width="2"
              indeterminate
              color="primary"
            ></v-progress-circular>
            Loading games
          </div>
          <div v-else>
            <div class="pa-6" v-if="!records.length && !recordsLoading">
              <div class="d-flex flex-column bs-gap-2 text-center grey--text">
                <div class="d-flex justify-center bs-gap-1">
                  <img
                    src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                    height="20"
                    style="filter: grayscale(100%)"
                    class="d-block"
                  />
                  <img
                    src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                    height="20"
                    style="filter: grayscale(100%)"
                    class="d-block"
                  />
                  <img
                    src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                    height="20"
                    style="filter: grayscale(100%)"
                    class="d-block"
                  />
                </div>
                No games available
              </div>
            </div>
            <div v-else>
              <div class="pa-6" v-if="!filterGames.length && !recordsLoading">
                <div class="d-flex flex-column bs-gap-2 text-center grey--text">
                  <div class="d-flex justify-center bs-gap-1">
                    <img
                      src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                      height="20"
                      style="filter: grayscale(100%)"
                      class="d-block"
                    />
                    <img
                      src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                      height="20"
                      style="filter: grayscale(100%)"
                      class="d-block"
                    />
                    <img
                      src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                      height="20"
                      style="filter: grayscale(100%)"
                      class="d-block"
                    />
                  </div>
                  <span v-if="search"
                    >No games found based on your search {{ `"${search}"` }}</span
                  >
                  <span v-else>No games found</span>
                </div>
              </div>
              <Games v-else :games="filterGames" />
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <v-card class="elevation-0">
          <div class="pa-4 pa-sm-6">
            <div class="d-flex align-center justify-space-between bs-gap-4">
              <h1 class="font-weight-bold">Basketball Ending</h1>
              <v-dialog
                ref="filter"
                v-model="filter"
                :return-value.sync="date"
                width="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    :color="filter ? 'primary' : undefined"
                    icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-calendar-clock</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text @click="filter = false"> Cancel </v-btn>
                  <v-btn depressed class="primary" @click="filterDate"> Ok </v-btn>
                </v-date-picker>
              </v-dialog>
            </div>
          </div>
        </v-card>
        <div
          class="pa-12 d-flex align-center justify-center text-center bs-gap-3 grey--text"
        >
          <div class="d-flex flex-column bs-gap-2 text-center grey--text">
            <div class="d-flex justify-center bs-gap-2">
              <img
                width="32"
                height="32"
                src="https://img.icons8.com/fluency/48/nothing-found.png"
                alt="nothing-found"
              />
            </div>
            <span>No games found</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <v-card class="elevation-0">
        <div class="pa-4 pa-sm-6">
          <h1 class="font-weight-bold">Basketball Ending</h1>
        </div>
      </v-card>
      <div
        class="pa-12 d-flex align-center justify-center text-center bs-gap-3 grey--text"
      >
        <v-progress-circular
          :size="14"
          :width="2"
          indeterminate
          color="primary"
        ></v-progress-circular>
        Loading games
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Games from "@/components/app/ending/Games.vue";
import responseGet from "@/helpers/api_request_get.js";
import formatDate from "@/helpers/formatDatev2.js";
// import Tutorial from "@/components/app/ending/Tutorial.vue";

export default {
  components: {
    Games,
    // Tutorial,
  },
  data: () => ({
    // adsModal: false,
    filter: false,
    league: "",
    date: "",
    date_menu: false,
    hasFilter: false,
    search: "",
    searchGame: false,
    slides: ["assets/"],
    records: [],
    recordsLoading: false,
    merged_leagues: [],
    league_id: "0",
    merged_schedules: [],
    schedule: null,
  }),
  computed: {
    ...mapState(["appData", "actualPoints"]),
    searchedGames() {
      return this.records.filter((game) => {
        return game.game_name
          .toLowerCase()
          .includes(this.search ? this.search.toLowerCase() : "");
      });
    },
    theActualPoints() {
      if (this.actualPoints) {
        return this.moneyFormat(parseFloat(this.actualPoints));
      } else {
        return 0;
      }
    },
    filterGames() {
      if (this.records && this.records.length != 0) {
        if (this.league_id != "0" && this.schedule === null) {
          return this.records.filter((game) => {
            return (
              game.league_id == this.league_id &&
              (game.team_a
                .toLowerCase()
                .includes(this.search ? this.search.toLowerCase() : "") ||
                game.team_b
                  .toLowerCase()
                  .includes(this.search ? this.search.toLowerCase() : ""))
            );
          });
        } else if (this.league_id == "0" && this.schedule !== null) {
          return this.records.filter((game) => {
            return (
              game.formatted_schedule == this.schedule &&
              (game.team_a
                .toLowerCase()
                .includes(this.search ? this.search.toLowerCase() : "") ||
                game.team_b
                  .toLowerCase()
                  .includes(this.search ? this.search.toLowerCase() : ""))
            );
          });
        } else if (this.league_id != "0" && this.schedule !== null) {
          return this.records.filter((game) => {
            return (
              game.league_id == this.league_id &&
              game.formatted_schedule == this.schedule &&
              (game.team_a
                .toLowerCase()
                .includes(this.search ? this.search.toLowerCase() : "") ||
                game.team_b
                  .toLowerCase()
                  .includes(this.search ? this.search.toLowerCase() : ""))
            );
          });
        } else {
          return this.records.filter((game) => {
            return (
              game.team_a
                .toLowerCase()
                .includes(this.search ? this.search.toLowerCase() : "") ||
              game.team_b
                .toLowerCase()
                .includes(this.search ? this.search.toLowerCase() : "")
            );
          });
        }
      }

      return this.records;
    },
    mergedLeagues() {
      const leagues = {};

      this.merged_leagues.forEach((item) => {
        const { league_id } = item;
        if (!leagues[league_id]) {
          leagues[league_id] = [];
        }
        leagues[league_id].push(item);
      });

      return leagues;
    },
    mergedSchedules() {
      // .substring(0, 10)

      const schedules = {};

      this.merged_schedules.forEach((item) => {
        const { formatted_schedule } = item;
        if (!schedules[formatted_schedule]) {
          schedules[formatted_schedule] = [];
        }
        schedules[formatted_schedule].push(item);
      });

      return schedules;
    },
  },
  mounted() {
    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      this.$store.commit("setGeoLat", latitude);
      this.$store.commit("setGeoLong", longitude);
    };

    const error = (err) => {
      console.log(err);
    };

    navigator.geolocation.getCurrentPosition(success, error);

    // this.loadRecords();
    this.getGames();
  },
  methods: {
    ...mapActions("ending_games", ["getRecords"]),
    // ...mapActions("logout", ["doLogout"]),

    async getGames() {
      this.recordsLoading = true;

      const form_data = new FormData();
      form_data.append("scheduled_date_start", this.date);
      form_data.append("scheduled_date_end", this.date);
      const params = new URLSearchParams(form_data);

      const res = await responseGet(params, "ending");

      if (res.status == 200) {
        this.records = res.data.games;
        this.merged_leagues = res.data.games;
        this.merged_schedules = res.data.games;
        // this.setDefaultSchedule();
      }
      //  else if (res.status == 401) {
      //   await this.doLogout();
      //   this.$router.push({ name: "Home", query: { is_force: 1 } });

      //   this.$root.$emit("setOverlay", false);
      // }

      this.recordsLoading = false;
    },
    selectLeague(id) {
      if (this.league == id) {
        this.league = "";
      } else {
        this.league = id;
      }
    },
    loadRecordsByLeague(id) {
      if (id) {
        this.league = id;
        this.loadRecords();
      }
    },
    reset() {
      this.filter = false;

      this.league = "";
      this.date = "";

      this.loadRecords();
    },
    async loadRecords() {
      this.filter = false;
      this.search = "";

      if (!this.recordsLoading) {
        this.$store.commit("ending_games/setRecords", []);

        const formData = new FormData();
        formData.append("scheduled_date_start", this.date);
        formData.append("scheduled_date_end", this.date);
        formData.append("league_id", this.league);

        const getData = new URLSearchParams(formData);

        await this.getRecords(getData);

        if (this.date || this.league) {
          this.hasFilter = true;
        } else {
          this.hasFilter = false;
        }
      }
    },
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    formatSchedule(date) {
      formatDate(date, "date");
    },
    getDayOfDate(date) {
      const dateObj = new Date(date);
      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const dayIndex = dateObj.getDay();

      let dayOfWeek = "";
      dayOfWeek = daysOfWeek[dayIndex];
      return dayOfWeek;
    },
    setDefaultSchedule() {
      const schedules = {};

      this.merged_schedules.forEach((item) => {
        const { schedule } = item;
        if (!schedules[schedule]) {
          schedules[schedule] = [];
        }
        schedules[schedule].push(item);
      });

      this.schedule = Object.keys(schedules)[0];
    },
    filterDate() {
      this.$refs.filter.save(this.date);
      this.filter = false;

      this.getGames();
    },
  },
  watch: {
    date() {
      this.date = this.date ?? "";
    },
    league() {
      this.league = this.league ?? "";
    },
  },
};
</script>
