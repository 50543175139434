<template>
  <div>
    <div
      class="d-flex flex-column align-center bs-gap-2 bs-text-center mb-4 mb-sm-6"
    >
      <h2 class="font-weight-bold">{{ game.league }}</h2>
      <span class="grey--text">{{ gameTimeFormat(game.schedule) }}</span>
      <span>{{
        game.team_a_name +
        " " +
        game.team_a_alias +
        " VS " +
        game.team_b_name +
        " " +
        game.team_b_alias
      }}</span>
    </div>

    <div class="d-flex justify-center">
      <div class="bs-flex flex-column align-center bs-text-center">
        <v-avatar size="80" class="light rounded-lg bs-border pa-3 me-3">
          <v-img :src="game.team_a_logo" :alt="game.team_b_name" contain>
          </v-img>
        </v-avatar>
      </div>
      <div class="bs-flex flex-column align-center bs-text-center">
        <v-avatar size="80" class="light rounded-lg bs-border pa-3">
          <v-img :src="game.team_b_logo" :alt="game.team_b_name" contain>
          </v-img>
        </v-avatar>
      </div>
    </div>

    <div class="text-center mt-4 mt-sm-6">
      <div class="grey--text mb-4">Result</div>

      <div class="bs-border bs-overflow-hidden rounded-lg">
        <v-simple-table dense style="pointer-events: none">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center px-4">
                  <h3 class="font-weight-bold">1st</h3>
                </th>
                <th class="text-center px-4">
                  <h3 class="font-weight-bold">2nd</h3>
                </th>
                <th class="text-center px-4">
                  <h3 class="font-weight-bold">3rd</h3>
                </th>
                <th class="text-center px-4">
                  <h3 class="font-weight-bold">4th</h3>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td class="font-weight-bold px-4">
                  {{
                    game.results[0] ? game.results[0].combination_number : "-"
                  }}
                </td>
                <td class="font-weight-bold px-4">
                  {{
                    game.results[1] ? game.results[1].combination_number : "-"
                  }}
                </td>
                <td class="font-weight-bold px-4">
                  {{
                    game.results[2] ? game.results[2].combination_number : "-"
                  }}
                </td>
                <td class="font-weight-bold px-4">
                  {{
                    game.results[3] ? game.results[3].combination_number : "-"
                  }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
  </div>
</template>

<script>
import formatDate from "@/helpers/formatDate";

export default {
  props: {
    game: {
      required: true,
    },
  },

  methods: {
    gameTimeFormat(date) {
      return formatDate(date, "format", "MMM dd, Y 'at' hh:mm a");
    },
  },
};
</script>
