<template>
  <div class="games">
    <div class="bs-row bs-gx-3 bs-gy-4">
      <div class="bs-col-md-6 bs-col-lg-4" v-for="game in games" :key="game.id">
        <v-card
          class="dark rounded-lg overflow-visible elevation-10 mt-2"
          style="position: relative"
        >
          <div
            class="px-4 px-sm-6"
            style="position: absolute; top: -1rem; right: 0; left: 0"
          >
            <div
              :class="$vuetify.theme.dark ? 'light' : 'dark'"
              class="rounded-lg elevation-1 d-flex justify-space-between bs-gap-4 px-4 py-3"
            >
              <span class="font-weight-bold">{{
                trimString(game.league)
              }}</span>
              <span v-if="game.game_status_id === null" class="grey--text">
                Not Started
              </span>
              <span
                v-else-if="game.game_status_id == 1"
                class="d-flex align-center bs-gap-2 grey--text"
              >
                <!-- src="https://img.icons8.com/emoji/48/red-paper-lantern-emoji.png"
                height="18" class="d-inline swinging-icon" -->
                <img
                  src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                  height="18"
                  class="d-inline fa-spin"
                />
                Ongoing ∙ 1st Q
              </span>
              <span
                v-else-if="game.game_status_id == 2"
                class="d-flex align-center bs-gap-2 grey--text"
              >
                <img
                  src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                  height="18"
                  class="d-inline fa-spin"
                />
                Ongoing ∙ 2nd Q
              </span>
              <span
                v-else-if="game.game_status_id == 3"
                class="d-flex align-center bs-gap-2 grey--text"
              >
                <img
                  src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                  height="18"
                  class="d-inline fa-spin"
                />
                Ongoing ∙ 3rd Q
              </span>
              <span
                v-else-if="game.game_status_id == 4"
                class="d-flex align-center bs-gap-2 grey--text"
              >
                <img
                  src="https://img.icons8.com/emoji/96/null/basketball-emoji.png"
                  height="18"
                  class="d-inline fa-spin"
                />
                Ongoing ∙ 4th Q
              </span>
              <span v-else class="grey--text">Game Ended</span>
            </div>
          </div>
          <div class="pa-4 pa-sm-6">
            <div class="d-flex align-center bs-gap-3 mt-8 mt-sm-6">
              <div class="d-inline-flex">
                <v-avatar size="64" class="light rounded-lg pa-3 me-3">
                  <v-img
                    :src="game.team_a_logo"
                    :alt="game.team_a_name"
                    contain
                  >
                  </v-img>
                </v-avatar>
                <v-avatar size="64" class="light rounded-lg pa-3">
                  <v-img
                    :src="game.team_b_logo"
                    :alt="game.team_b_name"
                    contain
                  >
                  </v-img>
                </v-avatar>
              </div>
              <div style="width: 100% !important">
                <div class="d-flex flex-column align-center text-center">
                  <span>
                    {{
                      trimString(game.team_a_name + " " + game.team_a_alias)
                    }}</span
                  >
                  <span class="grey--text">vs.</span>
                  <span>
                    {{
                      trimString(game.team_b_name + " " + game.team_b_alias)
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="px-4 px-sm-6 pb-4 pb-sm-6">
            <div class="d-flex justify-space-between align-end bs-gap-4">
              <span class="grey--text">{{ formatTheDate(game.schedule) }}</span>
              <BetGame
                :is_started="game.is_started"
                :game_id="game.game_id"
                :bet_count="game.bet_count"
                @clicked="showSlots"
                style="width: 145px"
              />
            </div>
          </div>
        </v-card>
      </div>
    </div>

    <v-dialog
      v-model="cardDialog"
      scrollable
      persistent
      max-width="500"
      class="card-dialog"
    >
      <v-card class="bet-card bs-shadow-none">
        <div class="pa-4 pa-sm-6" style="overflow-y: auto">
          <div class="d-flex justify-space-between align-center mb-4 mb-sm-6">
            <div class="d-flex align-center bs-gap-2">
              <v-btn
                icon
                small
                class="light"
                v-if="stepper == 2"
                @click="stepper = 1"
              >
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <h3 class="text-uppercase font-weight-bold ms-2">Card Details</h3>
            </div>
            <v-btn
              icon
              depressed
              small
              @click="
                cardDialog = false;
                betDialog = false;
              "
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <GameDetails :game="selectedGame" class="mb-4 mb-sm-6" />
          <v-stepper v-model="stepper" class="bs-shadow-none">
            <v-stepper-items>
              <v-stepper-content step="1" class="bs-shadow-none pa-0">
                <ActualCard
                  :cardData="slotsWithBetCount"
                  :actualPoints="actualPoints"
                  @slotSelected="slotSelected"
                  @viewBets="viewBetsName"
                />
              </v-stepper-content>

              <v-stepper-content step="2" class="bs-shadow-none pa-0">
                <div v-if="selectedCombination">
                  <div
                    class="light text-center rounded-lg pa-4 pa-sm-6 mb-4 mb-sm-6 mx-auto"
                    style="max-width: calc(160px + 1rem)"
                  >
                    <h1 class="font-weight-bold primary--text mb-1">
                      {{ selectedCombination.slot }}
                    </h1>
                    Combination
                  </div>
                  <div
                    class="bs-row bs-g-4"
                    v-if="
                      (traditionalBets && traditionalBets.length > 0) ||
                      (perQuarterBets && perQuarterBets.length > 0)
                    "
                  >
                    <div class="bs-col-6">
                      <div class="text-center grey--text mb-4">Traditional</div>
                      <div
                        class="amount-card rounded-lg"
                        v-for="bet in traditionalBets"
                        :key="bet.bet_amount_id"
                      >
                        <div>
                          <div
                            class="d-flex justify-space-between align-center mb-3"
                          >
                            <div class="d-flex bs-gap-2">
                              <span
                                :class="
                                  parseInt(bet.available_slots) > 0
                                    ? 'success--text'
                                    : ''
                                "
                              >
                                Slots
                              </span>
                              <span
                                :class="
                                  parseInt(bet.available_slots) > 0
                                    ? 'success--text'
                                    : ''
                                "
                                >{{ bet.available_slots }}</span
                              >
                            </div>
                            <a
                              href="#"
                              class="primary--text"
                              @click.prevent="viewPrize(bet)"
                            >
                              Prize
                            </a>
                          </div>
                        </div>
                        <div>
                          <v-btn
                            color="primary"
                            class="justify-space-between"
                            block
                            depressed
                            style="position: relative"
                            @click="
                              betConfirm(
                                bet.bet_amount_id,
                                bet.amount,
                                bet.bet_type_id
                              )
                            "
                            :disabled="
                              selectedGame.is_started
                                ? true
                                : parseInt(bet.available_slots) > 0
                                ? false
                                : true
                            "
                          >
                            {{ moneyFormat(parseFloat(bet.amount)) }}
                            <div
                              class="d-flex align-center"
                              style="
                                position: absolute;
                                top: 0;
                                right: 1rem;
                                bottom: 0;
                              "
                            >
                              <v-badge
                                inline
                                color="green"
                                v-if="parseInt(bet.bet_count) > 0"
                                :content="bet.bet_count"
                              >
                              </v-badge>
                              <v-icon v-else>mdi-arrow-right-thin</v-icon>
                            </div>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                    <div class="bs-col-6">
                      <div class="text-center grey--text mb-4">Per Quarter</div>
                      <div
                        class="amount-card rounded-lg"
                        v-for="bet in perQuarterBets"
                        :key="bet.bet_amount_id"
                      >
                        <div>
                          <div
                            class="d-flex justify-space-between align-center mb-3"
                          >
                            <div class="d-flex bs-gap-2">
                              <span
                                :class="
                                  parseInt(bet.available_slots) > 0
                                    ? 'success--text'
                                    : 'grey--text'
                                "
                              >
                                Slots
                              </span>
                              <span
                                :class="
                                  parseInt(bet.available_slots) > 0
                                    ? 'success--text'
                                    : 'grey--text'
                                "
                                >{{ bet.available_slots }}</span
                              >
                            </div>
                            <a
                              href="#"
                              class="primary--text"
                              @click.prevent="viewPrize(bet)"
                            >
                              Prize
                            </a>
                          </div>
                        </div>
                        <div>
                          <v-btn
                            color="primary"
                            class="justify-space-between"
                            block
                            depressed
                            style="position: relative"
                            @click="
                              betConfirm(
                                bet.bet_amount_id,
                                bet.amount,
                                bet.bet_type_id
                              )
                            "
                            :disabled="
                              selectedGame.is_started
                                ? true
                                : parseInt(bet.available_slots) > 0
                                ? false
                                : true
                            "
                          >
                            {{ moneyFormat(parseFloat(bet.amount)) }}
                            <div
                              class="d-flex align-center justify-space-between"
                              style="
                                position: absolute;
                                top: 0;
                                right: 1rem;
                                bottom: 0;
                              "
                            >
                              <v-badge
                                inline
                                color="green"
                                v-if="parseInt(bet.bet_count) > 0"
                                :content="bet.bet_count"
                              >
                              </v-badge>
                              <v-icon v-else>mdi-arrow-right-thin</v-icon>
                            </div>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="d-flex justify-center align-center text-center bs-gap-3 pa-4 pa-sm-6"
                  >
                    <v-progress-circular
                      indeterminate
                      :size="14"
                      :width="2"
                      color="primary"
                    ></v-progress-circular>
                    Loading ...
                  </div>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="betsNameDialog" fullscreen persistent scrollable>
      <v-card>
        <v-card-title class="pa-0 mx-2 mt-2">
          <v-row justify="space-between" no-gutters>
            <v-col cols="auto">
              <v-btn
                small
                color="secondary"
                depressed
                @click="closeBetsNameDialog"
              >
                Back
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <span class="overline">
                {{ theActualPoints }} Token{{
                  parseInt(actualPoints) > 1 ? "s" : ""
                }}
              </span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="px-2 px-md-4" v-if="selectedCombination">
          <GameDetails :game="selectedGame" />
          <div class="text-center mt-3">
            <p class="ma-0 pa-0 subtitle-1 font-weight-bold primary--text">
              {{ selectedCombination.slot }}
            </p>
            <p class="ma-0 pa-0 caption">Combination Number</p>
          </div>
          <div v-if="betsNameLoading" class="mt-3 text-center">
            <v-progress-circular
              :width="3"
              color="secondary"
              indeterminate
            ></v-progress-circular>
          </div>
          <div class="mt-3" v-else>
            <p class="mb-2">Transactions</p>
            <!-- <v-simple-table :class="$vuetify.theme.dark ? 'actual-card-dark' : 'actual-card'" dense> -->
            <v-card>
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">Transaction</th>
                      <th class="text-left">Transaction Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in betsName" :key="index">
                      <td>{{ item.name || "-" }}</td>
                      <td>{{ moneyFormat(parseFloat(item.amount)) }}</td>
                      <td>{{ formatTheDate(item.created_at) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="displayTraditionalOption"
      v-model="betConfirmDialog"
      :persistent="betLoading"
      max-width="400"
    >
      <v-card class="bs-shadow-none">
        <div class="pa-4 pa-sm-6">
          <h3 class="text-uppercase font-weight-bold mb-2">Confirm</h3>
          <v-form ref="betForm" @submit.prevent="bet">
            <v-text-field
              v-model="betName"
              :rules="[rules.alphaSpace, rules.maximum(100)]"
              label="Enter Name (optional)"
              hide-details="auto"
              outlined
              class="mb-2"
              dense
              v-if="
                (appData && appData.account_details.agent_id) ||
                (appData &&
                  appData.account_details.investor_sub_agent_player_id &&
                  appData.account_details.investor_sub_type_id != 2 &&
                  appData.account_details.investor_type_id != 1)
              "
            ></v-text-field>
            <div v-if="bet_type_id == 1" class="mb-4">
              <div
                class="light rounded-lg d-flex flex-column text-center my-4 pa-4"
              >
                <h1 class="font-weight-bold primary--text mb-0">
                  {{ moneyFormat(parseFloat(bet_amount)) }}
                </h1>
                Token Per Quarter
              </div>
              <div class="grey--text mb-2">Choose your lucky quarter</div>
              <div
                class="d-flex justify-space-between bs-gap-2"
                style="flex-wrap: nowrap !important"
              >
                <div class="d-inline-flex" style="width: 20%">
                  <v-btn
                    small
                    block
                    depressed
                    :color="selectedQuarterId == 5 ? 'success' : 'light'"
                    @click="selectQuarter(5)"
                    >All</v-btn
                  >
                </div>
                <div class="d-inline-flex" style="width: 20%">
                  <v-btn
                    small
                    block
                    depressed
                    :color="selectedQuarterId == 1 ? 'success' : 'light'"
                    @click="selectQuarter(1)"
                    >1st</v-btn
                  >
                </div>
                <div class="d-inline-flex" style="width: 20%">
                  <v-btn
                    small
                    block
                    depressed
                    :color="selectedQuarterId == 2 ? 'success' : 'light'"
                    @click="selectQuarter(2)"
                    >2nd</v-btn
                  >
                </div>
                <div class="d-inline-flex" style="width: 20%">
                  <v-btn
                    small
                    block
                    depressed
                    :color="selectedQuarterId == 3 ? 'success' : 'light'"
                    @click="selectQuarter(3)"
                    >3rd</v-btn
                  >
                </div>
                <div class="d-inline-flex" style="width: 20%">
                  <v-btn
                    small
                    block
                    depressed
                    :color="selectedQuarterId == 4 ? 'success' : 'light'"
                    @click="selectQuarter(4)"
                    >4th</v-btn
                  >
                </div>
              </div>
            </div>
            <div
              class="grey--text mb-4 mb-sm-6"
              v-if="bet_type_id == 1 && selectedQuarterId"
            >
              You're about to place
              <span class="success--text">{{
                moneyFormat(parseFloat(totalBetAmount))
              }}</span>
              {{ parseInt(totalBetAmount) > 1 ? "tokens" : "token" }} for
              combination
              <span class="success--text">{{ selectedCombination.slot }}</span>
              valid for
              <span class="success--text">{{ selectedQuarter }}</span>
              <span>{{
                selectedQuarterId == 5 ? " quarters" : " quarter ONLY"
              }}</span
              >, please confirm to proceed.
            </div>
            <div class="grey--text mb-4 mb-sm-6" v-if="bet_type_id == 2">
              You're about to place {{ moneyFormat(parseFloat(bet_amount)) }}
              {{ parseInt(bet_amount) > 1 ? "tokens" : "token" }} for
              combination {{ selectedCombination.slot }} valid for all quarters,
              please confirm to proceed.
            </div>

            <v-alert
              type="info"
              text
              class="my-4 my-sm-6"
              v-show="bet_type_id == 2 || selectedQuarterId"
            >
              I hereby agree and understand that after clicking the "Confirm"
              button, the bets placed within the system shall be non-refundable.
              Winnings are subject to government mandated taxes.
            </v-alert>
            <div v-show="bet_type_id == 1 && !selectedQuarterId">&nbsp;</div>
            <div class="d-flex justify-end bs-gap-2">
              <v-btn
                :disabled="betLoading"
                text
                @click="betConfirmDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                ref="yes"
                color="primary"
                depressed
                type="submit"
                :loading="betLoading"
                :disabled="bet_type_id == 1 && !selectedQuarterId"
              >
                Confirm — {{ totalBetAmount }}
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
      v-else
      v-model="betConfirmDialog"
      :persistent="betLoading"
      max-width="400"
    >
      <v-card class="bs-shadow-none">
        <div class="pa-4 pa-sm-6">
          <h3 class="text-uppercase font-weight-bold mb-2">Confirm</h3>
          <v-form ref="betForm" @submit.prevent="bet">
            <div class="grey--text mb-4">
              You're about to place {{ moneyFormat(parseFloat(bet_amount)) }}
              {{ parseInt(bet_amount) > 1 ? "tokens" : "token" }}, please
              confirm that you would like to proceed.
            </div>

            <v-text-field
              v-model="betName"
              :rules="[rules.alphaSpace, rules.maximum(100)]"
              label="Enter Name (optional)"
              hide-details="auto"
              outlined
              class="mb-4 mb-sm-6"
              dense
              v-if="
                (appData && appData.account_details.agent_id) ||
                (appData &&
                  appData.account_details.investor_sub_agent_player_id &&
                  appData.account_details.investor_sub_type_id != 2 &&
                  appData.account_details.investor_type_id != 1)
              "
            ></v-text-field>

            <v-alert type="info" text class="my-4 my-sm-6">
              I hereby agree and understand that after clicking the "Confirm"
              button, the bets placed within the system shall be non-refundable.
              Winnings are subject to government mandated taxes.
            </v-alert>

            <div class="d-flex justify-end bs-gap-2">
              <v-btn
                :disabled="betLoading"
                text
                @click="betConfirmDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                ref="yes"
                color="primary"
                depressed
                type="submit"
                :loading="betLoading"
              >
                Confirm
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewPrizeDialog" width="300">
      <v-card class="bs-shadow-none">
        <div class="pa-4 pa-sm-6">
          <div class="font-weight-bold mb-4 mb-sm-6">
            {{ `Prize — ${viewPrizeData.bet_type}` }}
          </div>
          <div class="light rounded-lg text-center pa-4 mb-4">
            <div class="primary--text mb-1">
              {{ moneyFormat(parseFloat(viewPrizeData.amount)) }}
            </div>
            Token Amount
          </div>
          <div
            v-if="displayTraditionalOption"
            class="bs-border rounded-lg overflow-hidden"
          >
            <v-simple-table dense>
              <thead>
                <tr>
                  <th class="px-4">Quarter</th>
                  <th class="text-end px-4">Prize</th>
                </tr>
              </thead>
              <!-- <tbody v-if="viewPrizeData.bet_type == 'Traditional'">
                <tr>
                  <td class="px-4 grey--text">4th</td>
                  <td class="text-end px-4">
                    {{ viewPrizeData.fourth_quarter_prize }}
                  </td>
                </tr>
              </tbody> -->
              <tbody>
                <tr>
                  <td class="px-4 grey--text">1st</td>
                  <td class="text-end px-4">
                    {{ viewPrizeData.first_quarter_prize }}
                  </td>
                </tr>
                <tr>
                  <td class="px-4 grey--text">2nd</td>
                  <td class="text-end px-4">
                    {{ viewPrizeData.second_quarter_prize }}
                  </td>
                </tr>
                <tr>
                  <td class="px-4 grey--text">3rd</td>
                  <td class="text-end px-4">
                    {{ viewPrizeData.third_quarter_prize }}
                  </td>
                </tr>
                <tr>
                  <td class="px-4 grey--text">4th</td>
                  <td class="text-end px-4">
                    {{ viewPrizeData.fourth_quarter_prize }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <div v-else class="bs-border rounded-lg overflow-hidden">
            <v-simple-table dense>
              <thead>
                <tr>
                  <th class="px-4">Quarter</th>
                  <th class="text-end px-4">Prize</th>
                </tr>
              </thead>
              <tbody v-if="viewPrizeData.bet_type == 'Traditional'">
                <tr>
                  <td class="px-4 grey--text">4th</td>
                  <td class="text-end px-4">
                    {{ viewPrizeData.fourth_quarter_prize }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="px-4 grey--text">1st</td>
                  <td class="text-end px-4">
                    {{ viewPrizeData.first_quarter_prize }}
                  </td>
                </tr>
                <tr>
                  <td class="px-4 grey--text">2nd</td>
                  <td class="text-end px-4">
                    {{ viewPrizeData.second_quarter_prize }}
                  </td>
                </tr>
                <tr>
                  <td class="px-4 grey--text">3rd</td>
                  <td class="text-end px-4">
                    {{ viewPrizeData.third_quarter_prize }}
                  </td>
                </tr>
                <tr>
                  <td class="px-4 grey--text">4th</td>
                  <td class="text-end px-4">
                    {{ viewPrizeData.fourth_quarter_prize }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <div class="d-flex justify-end mt-4 mt-sm-6">
            <v-btn class="light" depressed @click="viewPrizeDialog = false">
              Close
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbarSuccess"
      top
      color="success"
      :timeout="snackbarSuccessTimeout"
    >
      <v-icon class="me-2"> mdi-check-circle-outline </v-icon>
      Success
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbarSuccess = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackbarError"
      top
      color="error"
      :timeout="snackbarErrorTimeout"
    >
      <v-icon class="me-2"> mdi-alert-circle-outline </v-icon>
      {{ betError }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbarError = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import formatDate from "@/helpers/formatDate";
import BetGame from "@/components/app/ending/BetGame";
import GameDetails from "@/components/app/ending/GameDetails";
import { mapState, mapActions } from "vuex";
import slotsData from "@/data/slots.json";
import ActualCard from "@/components/app/ending/ActualCard";
import formRules from "@/helpers/formRules";
import {
  pusherAvailableSlotsSubscribe,
  pusherAvailableSlotsUnsubscribe,
} from "@/pusher/config";

export default {
  components: { BetGame, GameDetails, ActualCard },
  props: {
    games: {
      required: true,
    },
  },
  data: () => ({
    cardDialog: false,
    betsNameDialog: false,
    betDialog: false,
    game_id: null,
    actualSlots: slotsData,
    selectedSlot: null,
    betConfirmDialog: false,
    bet_amount_id: null,
    bet_amount: null,
    snackbarSuccess: false,
    snackbarSuccessTimeout: -1,
    snackbarError: false,
    snackbarErrorTimeout: -1,
    rules: formRules,
    betName: "",
    viewPrizeDialog: false,
    viewPrizeData: "",
    stepper: 1,
    bet_type_id: null,
    selectedQuarter: "4th",
    selectedQuarterId: 4,
    betAmountMultiplier: 1,
  }),
  mounted() {
    window.addEventListener("keydown", (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        if (this.stepper == 2) {
          this.stepper = 1;
        }
      }
    });
  },
  computed: {
    ...mapState("ending_games", [
      "slots",
      "bets",
      "betsError",
      "betsLoading",
      "betsName",
      "betsNameError",
      "betsNameLoading",
      "betError",
      "betHasError",
      "betLoading",
    ]),
    ...mapState(["appData", "actualPoints", "geoLat", "geoLong"]),
    theActualPoints() {
      if (this.actualPoints) {
        return this.moneyFormat(parseFloat(this.actualPoints));
      } else {
        return 0;
      }
    },
    selectedGame() {
      return this.games.find((game) => {
        return parseInt(game.game_id) == parseInt(this.game_id);
      });
    },
    selectedCombination() {
      return this.actualSlots.find((slot) => {
        return parseInt(slot.slot_id) == parseInt(this.selectedSlot);
      });
    },
    slotsWithBetCount() {
      return this.actualSlots.map((actualSlot) => {
        const slotData = this.slots.find(
          (o) => o.slot_id == actualSlot.slot_id
        );
        let bet_count = 0;

        if (typeof slotData != "undefined") {
          bet_count = slotData.slot_bet_count;
        }

        return { ...actualSlot, bet_count };
      });
    },
    traditionalBets() {
      return this.bets.filter((bet) => {
        return bet.bet_type_id == 1;
      });
    },
    perQuarterBets() {
      return this.bets.filter((bet) => {
        return bet.bet_type_id == 2;
      });
    },
    totalBetAmount() {
      return this.bet_amount * this.betAmountMultiplier;
    },
    displayTraditionalOption() {
      const game = this.games.find((game) => {
        return parseInt(game.game_id) == parseInt(this.game_id);
      });

      if (game) {
        if (game.created_at >= "2023-07-13 00:00:00") {
          return true;
        }
      }

      return false;
    },
  },
  methods: {
    ...mapActions("ending_games", ["getBets", "getBetsName", "doBet"]),
    formatTheDate(theDate) {
      return formatDate(theDate);
    },
    moneyFormat(val) {
      return val.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    gameTimeFormat(date) {
      return formatDate(date, "format", "eee, MMM dd 'at' hh:mm a");
    },
    showSlots(game_id) {
      this.game_id = game_id;
      this.cardDialog = true;
    },
    viewBetsName(slot_id) {
      this.selectedSlot = slot_id;
      this.betsNameDialog = true;
      this.doGetBetsName();
    },
    async doGetBetsName() {
      if (!this.betsNameLoading) {
        this.$store.commit("ending_games/setBetsName", []);

        const formData = new FormData();
        formData.append("game_id", this.game_id);
        formData.append("slot_id", this.selectedSlot);

        const getData = new URLSearchParams(formData);

        await this.getBetsName(getData);
      }
    },
    slotSelected(slot_id) {
      this.selectedSlot = slot_id;
      // this.betDialog = true;
      this.doGetBets();
      this.subAvailableSlots();
      this.stepper = 2;
    },
    async doGetBets() {
      if (!this.betsLoading) {
        this.$store.commit("ending_games/setBets", []);

        const formData = new FormData();
        formData.append("game_id", this.game_id);
        formData.append("slot_id", this.selectedSlot);

        const getData = new URLSearchParams(formData);

        await this.getBets(getData);
      }
    },
    betConfirm(bet_amount_id, bet_amount, bet_type_id) {
      this.bet_amount_id = bet_amount_id;
      this.bet_amount = bet_amount;
      this.bet_type_id = bet_type_id;
      this.betConfirmDialog = true;
    },
    selectQuarter(val) {
      this.betAmountMultiplier = 1;

      switch (val) {
        case 1:
          this.selectedQuarter = "1st";
          break;
        case 2:
          this.selectedQuarter = "2nd";
          break;
        case 3:
          this.selectedQuarter = "3rd";
          break;
        case 4:
          this.selectedQuarter = "4th";
          break;
        case 5:
          this.betAmountMultiplier = 4;
          this.selectedQuarter = "ALL";
          break;
      }

      this.selectedQuarterId = val;
    },
    async bet() {
      this.snackbarSuccessTimeout = -1;
      this.snackbarErrorTimeout = -1;

      if (this.$refs.betForm.validate()) {
        if (!this.betLoading) {
          const formData = new FormData();
          formData.append("game_id", this.game_id);
          formData.append("slot_id", this.selectedSlot);
          formData.append("bet_amount_id", this.bet_amount_id);
          formData.append("bet_name", this.betName);
          formData.append("geo_lat", this.geoLat === null ? "" : this.geoLat);
          formData.append(
            "geo_long",
            this.geoLong === null ? "" : this.geoLong
          );
          formData.append(
            "selected_quarter_id",
            this.bet_type_id == 1 ? this.selectedQuarterId : 0
          );

          await this.doBet(formData);

          this.snackbarSuccess = false;
          this.snackbarError = false;

          if (!this.betHasError) {
            this.snackbarSuccess = true;
            this.snackbarSuccessTimeout = 4000;

            let countToAdd = 1;
            let amountToDeduct = this.bet_amount;
            if (this.bet_type_id == 1 && this.selectedQuarterId == 5) {
              countToAdd = 4;
              amountToDeduct = this.totalBetAmount;
            }

            // Add My Bet
            const theCardBetCount = this.bets.find(
              (bet) => bet.bet_amount_id == this.bet_amount_id
            );
            theCardBetCount.bet_count = +theCardBetCount.bet_count + countToAdd;

            // Add Card Slot
            const theCardSlotBetCount = this.slots.find(
              (o) => o.slot_id == this.selectedSlot
            );

            if (typeof theCardSlotBetCount != "undefined") {
              theCardSlotBetCount.slot_bet_count =
                +theCardSlotBetCount.slot_bet_count + countToAdd;
            } else {
              this.$store.commit("ending_games/setSlots", [
                { slot_id: this.selectedSlot, slot_bet_count: countToAdd },
                ...this.slots,
              ]);
            }

            // Add My Bet to Main
            const theBetCount = this.games.find(
              (game) => game.game_id == this.game_id
            );
            theBetCount.bet_count = +theBetCount.bet_count + countToAdd;

            // Deduct to points
            this.$store.commit(
              "setActualPoints",
              this.actualPoints - amountToDeduct
            );

            // Empty bet name
            this.betName = "";

            // Added bet sound
            const sound = new Audio(require("@/assets/audio/bet.wav"));
            sound.play();
          } else {
            if (this.betError) {
              this.snackbarError = true;
              this.snackbarErrorTimeout = 4000;
            }
          }

          this.betConfirmDialog = false;
        }
      }
    },
    closeCardDialog() {
      this.cardDialog = false;
      this.$router.replace({
        name: "EndingGames",
      });
    },
    closeBetsNameDialog() {
      this.betsNameDialog = false;
    },
    closeBetDialog() {
      this.betDialog = false;
    },
    viewPrize(bet) {
      this.viewPrizeDialog = true;
      this.viewPrizeData = bet;
    },
    subAvailableSlots() {
      const availableSlotsChannel = pusherAvailableSlotsSubscribe(
        this.game_id,
        this.selectedSlot
      );

      /* availableSlotsChannel.bind('plus', (data) => {
        const theSlotBetCount = this.bets.find((bet) => bet.bet_amount_id == data.bet_amount_id)
        theSlotBetCount.available_slots = +theSlotBetCount.available_slots + 1
      }) */
      availableSlotsChannel.bind("minus", (data) => {
        const theSlotBetCount = this.bets.find(
          (bet) => bet.bet_amount_id == data.bet_amount_id
        );

        let slotToDeduct = 1;
        if (data.selected_quarter_id == 5) {
          slotToDeduct = 4;
        }

        theSlotBetCount.available_slots =
          theSlotBetCount.available_slots - slotToDeduct;
      });

      // console.log('S', this.game_id, this.selectedSlot)
    },
    unSubAvailableSlots() {
      pusherAvailableSlotsUnsubscribe(this.game_id, this.selectedSlot);

      // console.log('U', this.game_id, this.selectedSlot)
    },
    trimString(team) {
      let index;

      if (this.$vuetify.breakpoint.mdAndUp) {
        index = 30;
      } else if (this.$vuetify.breakpoint.smAndUp) {
        index = 22;
      }

      if (team.length > index) {
        return team.substring(0, index) + " ...";
      } else {
        return team;
      }
    },
  },
  watch: {
    betConfirmDialog(visible) {
      if (visible) {
        this.betAmountMultiplier = 1;
        this.selectedQuarterId = 4;
        this.selectedQuarter = "4th";
        setTimeout(() => {
          this.$refs.yes.$el.focus();
        }, 200);
      }
    },
    $route(newVal) {
      this.betsNameDialog = newVal.query?.isBetsOpen || false;
      this.betDialog = newVal.query?.isBetOpen || false;
    },
    betDialog(newVal) {
      if (!newVal) {
        this.unSubAvailableSlots();
      }
    },
    cardDialog(newVal) {
      if (!newVal) {
        setTimeout(() => {
          this.stepper = 1;
        }, 300);
      }
    },
  },
};
</script>

<style>
/* .bet-card {
  height: calc(100vh - 3rem);
}

@media (max-width: 768px) {
  .bet-card {
    height: calc(100vh - 1rem);
  }
} */

.amount-card {
  margin-bottom: 1rem;
}

.amount-card:last-of-type {
  margin-bottom: 0 !important;
}
</style>
