<template>
  <div class="bs-row bs-gx-2">
    <div class="bs-col-3 bs-col-md-3 mt-2" v-for="item in slotData" :key="item.slot_id">
      <v-btn
        depressed
        block
        class="card-slot light justify-space-between"
        @click="selectSlot(item.slot_id)"
      >
        <div class="d-flex align-center bs-gap-2 bs-gap-sm-3">
          {{ item.slot }}
          <span class="green--text d-none d-sm-inline" v-if="item.bet_count > 0">
            {{ parseInt(item.bet_count).toLocaleString() }}
          </span>
        </div>
        <div
          v-if="
            appData &&
            (appData.account_details.agent_id ||
              appData.account_details.investor_sub_agent_player_id) &&
            appData.account_details.investor_sub_type_id
          "
        >
          <div
            class="card-slot-triangle"
            @click.stop="viewSlot(item.slot_id)"
            :class="item.bet_count > 0 ? 'border-green' : undefined"
          ></div>
        </div>
        <div v-else>
          <div
            class="card-slot-triangle"
            :class="item.bet_count > 0 ? 'border-green' : undefined"
          ></div>
        </div>
      </v-btn>
    </div>
  </div>
  <!-- <tr>
    <td
      v-for="item in slotData"
      :key="item.slot_id"
      style="width: 100px; cursor: pointer"
      class="card-slot text-left"
      @click="selectSlot(item.slot_id)"
    >
      <v-btn>
        <div class="d-flex bs-gap-2">
          {{ item.slot }}
          <span v-if="item.bet_count > 0" class="font-weight-bold success--text">
            {{ parseInt(item.bet_count).toLocaleString() }}
          </span>
        </div>
        <v-spacer></v-spacer>
        <div v-if="appData && appData.account_details.agent_id">
          <div
            v-if="item.bet_count > 0"
            @click.stop="viewSlot(item.slot_id)"
            class="card-slot-triangle-green"
          ></div>
          <div
            v-else
            :class="
              $vuetify.theme.dark
                ? 'card-slot-triangle-grey-dark'
                : 'card-slot-triangle-grey'
            "
          ></div>
        </div>
        <div v-else>
          <div v-if="item.bet_count > 0" class="card-slot-triangle-green"></div>
          <div
            v-else
            :class="
              $vuetify.theme.dark
                ? 'card-slot-triangle-grey-dark'
                : 'card-slot-triangle-grey'
            "
          ></div>
        </div>
      </v-btn>
    </td>
  </tr> -->
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    slotData: {
      required: true,
    },
  },
  computed: {
    ...mapState(["appData"]),
  },
  methods: {
    selectSlot(slot_id) {
      this.$emit("slotSelected", slot_id);
    },
    viewSlot(slot_id) {
      this.$emit("viewBets", slot_id);
    },
  },
};
</script>
