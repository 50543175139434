<template>
  <div block class="text-center">
    <div class="grey--text mb-4">Select your combination number</div>
    <div class="ending-card">
      <div style="margin-top: -8px">
        <ActualCardSlot
          :slotData="cardLayerA"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerB"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerC"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerD"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerE"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          class="mt-0 mt-sm-0"
          :slotData="cardLayerF"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerG"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerH"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerI"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerJ"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerK"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerL"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerM"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerN"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerO"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerP"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          class="mt-0 mt-sm-0"
          :slotData="cardLayerQ"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerR"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerS"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerT"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerU"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerV"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerW"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerX"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerY"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
      </div>

      <!-- <table
        class="rounded-lg"
        :class="$vuetify.theme.dark ? 'actual-card-dark' : 'actual-card'"
      >
        <ActualCardSlot
          :slotData="cardLayerA"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerB"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerC"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerD"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerE"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
      </table> -->

      <!-- <table
        :class="$vuetify.theme.dark ? 'actual-card-dark' : 'actual-card'"
      >
        <ActualCardSlot
          :slotData="cardLayerA"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerB"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerC"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerD"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerE"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
      </table> -->

      <!-- <table
        :class="$vuetify.theme.dark ? 'actual-card-dark' : 'actual-card'"
        class="mt-0 mt-sm-0"
      >
        <ActualCardSlot
          :slotData="cardLayerF"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerG"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerH"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerI"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerJ"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerK"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerL"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerM"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerN"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerO"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
      </table> -->

      <!-- <table
        :class="$vuetify.theme.dark ? 'actual-card-dark' : 'actual-card'"
        class="mt-0 mt-sm-0"
      >
        <ActualCardSlot
          :slotData="cardLayerP"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerQ"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerR"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerS"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerT"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerU"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerV"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerW"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerX"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
        <ActualCardSlot
          :slotData="cardLayerY"
          @slotSelected="slotSelected"
          @viewBets="viewBets"
        />
      </table> -->
    </div>
  </div>
</template>

<style scoped>
.ending-card {
  box-shadow: none !important;
  border: none !important;
}
</style>

<script>
import ActualCardSlot from "@/components/app/ending/ActualCardSlot";

export default {
  components: { ActualCardSlot },
  props: {
    cardData: {
      required: true,
    },
    actualPoints: {
      required: true,
    },
  },
  computed: {
    cardLayerA() {
      return this.cardData.filter((slot) => {
        return [1, 26, 51, 76].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerB() {
      return this.cardData.filter((slot) => {
        return [2, 27, 52, 77].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerC() {
      return this.cardData.filter((slot) => {
        return [3, 28, 53, 78].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerD() {
      return this.cardData.filter((slot) => {
        return [4, 29, 54, 79].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerE() {
      return this.cardData.filter((slot) => {
        return [5, 30, 55, 80].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerF() {
      return this.cardData.filter((slot) => {
        return [6, 31, 56, 81].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerG() {
      return this.cardData.filter((slot) => {
        return [7, 32, 57, 82].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerH() {
      return this.cardData.filter((slot) => {
        return [8, 33, 58, 83].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerI() {
      return this.cardData.filter((slot) => {
        return [9, 34, 59, 84].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerJ() {
      return this.cardData.filter((slot) => {
        return [10, 35, 60, 85].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerK() {
      return this.cardData.filter((slot) => {
        return [11, 36, 61, 86].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerL() {
      return this.cardData.filter((slot) => {
        return [12, 37, 62, 87].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerM() {
      return this.cardData.filter((slot) => {
        return [13, 38, 63, 88].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerN() {
      return this.cardData.filter((slot) => {
        return [14, 39, 64, 89].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerO() {
      return this.cardData.filter((slot) => {
        return [15, 40, 65, 90].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerP() {
      return this.cardData.filter((slot) => {
        return [16, 41, 66, 91].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerQ() {
      return this.cardData.filter((slot) => {
        return [17, 42, 67, 92].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerR() {
      return this.cardData.filter((slot) => {
        return [18, 43, 68, 93].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerS() {
      return this.cardData.filter((slot) => {
        return [19, 44, 69, 94].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerT() {
      return this.cardData.filter((slot) => {
        return [20, 45, 70, 95].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerU() {
      return this.cardData.filter((slot) => {
        return [21, 46, 71, 96].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerV() {
      return this.cardData.filter((slot) => {
        return [22, 47, 72, 97].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerW() {
      return this.cardData.filter((slot) => {
        return [23, 48, 73, 98].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerX() {
      return this.cardData.filter((slot) => {
        return [24, 49, 74, 99].includes(parseInt(slot.slot_id));
      });
    },
    cardLayerY() {
      return this.cardData.filter((slot) => {
        return [25, 50, 75, 100].includes(parseInt(slot.slot_id));
      });
    },
  },
  methods: {
    slotSelected(slot_id) {
      this.$emit("slotSelected", slot_id);
    },
    viewBets(slot_id) {
      this.$emit("viewBets", slot_id);
    },
  },
};
</script>
