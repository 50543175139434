<template>
  <div class="bet-game">
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <v-btn
        v-if="!is_started"
        block
        class="primary justify-space-between"
        depressed
        @click="selectCombination"
        :loading="loading"
        style="position: relative"
      >
        Play Now
        <div
          v-if="bet_count > 0"
          class="d-flex align-center"
          style="position: absolute; top: 0; right: 1rem; bottom: 0"
        >
          <v-badge inline :content="bet_count" color="green"></v-badge>
        </div>
        <v-icon v-else>mdi-arrow-right-thin</v-icon>
      </v-btn>
      <v-btn
        v-else
        block
        depressed
        class="light primary--text justify-space-between"
        @click="selectCombination"
        :loading="loading"
        style="position: relative; text-align: left !important"
      >
        View Card
        <div
          v-if="bet_count > 0"
          class="d-flex align-center"
          style="position: absolute; top: 0; right: 1rem; bottom: 0"
        >
          <v-badge inline :content="bet_count" color="green"></v-badge>
        </div>
        <v-icon v-else>mdi-arrow-right-thin</v-icon>
      </v-btn>
    </div>
    <div v-else>
      <v-btn
        v-if="!is_started"
        @click.prevent="selectCombination"
        :loading="loading"
        depressed
        block
        class="primary white--text justify-space-between"
      >
        Play Now
        <div
          v-if="bet_count > 0"
          class="d-flex align-center"
          style="position: absolute; top: 0; right: 1rem; bottom: 0"
        >
          <v-badge inline :content="bet_count" color="green"></v-badge>
        </div>
        <v-icon v-else>mdi-arrow-right-thin</v-icon>
      </v-btn>
      <v-btn
        v-else
        @click.prevent="selectCombination"
        depressed
        :loading="loading"
        class="light primary--text justify-space-between"
        block
      >
        View Card
        <div
          v-if="bet_count > 0"
          class="d-flex align-center"
          style="position: absolute; top: 0; right: 1rem; bottom: 0"
        >
          <v-badge inline :content="bet_count" color="green"></v-badge>
        </div>
        <v-icon v-else>mdi-arrow-right-thin</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: {
    is_started: {
      required: true,
    },
    game_id: {
      required: true,
    },
    bet_count: {
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState("ending_games", ["slotsError"]),
    ...mapState(["accountStatus"]),
  },
  methods: {
    ...mapActions("ending_games", ["getSlots"]),
    async selectCombination() {
      if (!this.loading) {
        this.loading = true;

        this.$store.commit("ending_games/setSlots", []);

        const formData = new FormData();
        formData.append("game_id", this.game_id);

        const getData = new URLSearchParams(formData);

        await this.getSlots(getData);

        if (!this.slotsError) {
          this.$emit("clicked", this.game_id);
        }

        this.loading = false;
      }
    },
  },
};
</script>
